/* Container */
.container {
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Header */
.header {
    text-align: center;
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

/* Form */
.form {
    display: flex;
    flex-direction: column;
}

/* Form Group */
.formGroup {
    margin-bottom: 20px;
}

/* Label */
.label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    font-size: 1rem;
    color: #555;
}

/* Input */
.input, .textarea {
    width: 75%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    transition: border-color 0.3s;
}

.input:focus, .textarea:focus {
    border-color: #007BFF;
    outline: none;
}

/* Button */
.button {
    padding: 10px 15px;
    font-size: 1rem;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #0056b3;
}

.button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Error Message */
.error {
    color: red;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

/* Container styling for the login redirect */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 2rem;
    border-radius: 8px;
    background-color: var(--white);
    color: var(--black);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
}

/* Title styling */
.title {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--black);
    margin-bottom: 5px;
}

/* Description text styling */
.description {
    font-size: 1rem;
    color: var(--black);
    margin-bottom: 15px;
    line-height: 1.5;
}

/* Button container for alignment */
.buttonContainer {
    display: flex;
    gap: 10px;
}

/* Login button styling */
.loginButton, .signUpButton {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
}

/* Styling for the login button */
.loginButton {
    background-color: var(--red);
    color: var(--white);
}

.loginButton:hover {
    background-color: darkred;
}

/* Styling for the sign up button */
.signUpButton {
    background-color: var(--gray);
    color: var(--black);
}

.signUpButton:hover {
    background-color: #b3b3b3; /* Slightly darker gray for hover */
}

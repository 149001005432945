:root {
    --red: #FF1801; /* Primary accent */
    --black: #000000; /* Backgrounds and main text */
    --white: #FFFFFF; /* Text and backgrounds */
    --gray: #e5e5e5; /* Lighter gray for secondary elements */
    --gold: #FFC700; /* Richer gold for boosted squares */
    --silver: #c0c0c0; /* More vibrant silver for alternative highlight */
    --bronze: #cd7f32;

  }


*{
    margin: 0;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 550;
}

button {
    background-color: var(--red); /* Primary accent color */
    color: var(--white); /* Text color */
    border: none;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }

  button:hover {
    background-color: #e60000; /* Slightly darker red for hover */
    transform: scale(1.05); /* Small pop effect */
  }

  button:active {
    background-color: #cc0000; /* Even darker red for active state */
    transform: scale(1); /* Reset scale */
  }

  button:disabled {
    background-color: var(--gray); /* Disabled button */
    color: var(--black);
    cursor: not-allowed;
    opacity: 0.6;
  }

  .hoverable{
    transition: transform 0.2s ease, background-color 0.2s ease;
  }

  .hoverable:hover{
    transform: scale(1.03);
    cursor: pointer;
  }

  html, body {
    height: 100%;
    margin: 0;
    overflow: hidden;
}

.content {
    height: calc(100dvh - 70px - 50px); /* Subtract 70px for NavBar and 50px for the extra margin */
    overflow-y: auto;
    padding: clamp(5px, 2vw, 25px);
    margin-bottom: 50px; /* Ensures 50px space at the bottom */
}


/* Styling for input fields */
input{
  width: auto;
  padding: 10px;
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 1rem;
  border: 1px solid var(--gray);
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  color: var(--black);
  background-color: var(--white);
}

/* Focus styling for inputs */
.inputField:focus {
  border-color: var(--red); /* Match your theme color */
}

/* Styling for textarea fields */
textarea{
  width: auto;
  padding: 10px;
  margin-top: 8px;
  margin-bottom: 16px;
  font-size: 1rem;
  border: 1px solid var(--gray);
  border-radius: 4px;
  outline: none;
  resize: vertical; /* Allow vertical resizing only */
  transition: border-color 0.3s ease;
  color: var(--black);
  background-color: var(--white);
  line-height: 1.5;
  min-height: 100px; /* Set a minimum height */
}

/* Focus styling for textarea */
.textareaField:focus {
  border-color: var(--red); /* Match your theme color */
}


/* Show Mobile class only if viewport width is below 768px */
@media (max-width: 700px) {
    .Mobile {
        display: block;
    }

    .Desktop {
        display: none !important;
    }


}

@media (min-width: 700px) {
    .Mobile {
        display: none !important;
    }


}

.Block{
    background-color: var(--white);
    border: 1px solid var(--gray);
    border-left: 5px solid var(--red);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: box-shadow 0.3s, transform 0.3s;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}


/* Example adjustment: Replace <NavBarHeight> with a fixed or dynamic value */


.NavBar {
    background-color: var(--red);
    width: auto;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    z-index: 1000;
    flex-wrap: wrap;
}

.logo {
    color: var(--white);
    margin: auto;
}

.nav {
    color: var(--white);
    display: flex;
    gap: 50px;
    align-items: center;
    margin: auto;
}

.MobileNav {
    position: fixed; /* Sticks to the bottom */
    bottom: 0;
    left: 0;
    width: 100%; /* Full width of the screen */
    height: 60px; /* Height of the navbar */
    background-color: var(--white); /* Red styling */
    display: flex; /* Flexbox for layout */
    justify-content: space-around; /* Evenly space items */
    align-items: center; /* Center items vertically */
    z-index: 1000; /* Ensure it stays on top */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for separation */
}

/* Style for navigation items */
.MobileNav a {
    text-decoration: none; /* Remove underline */
    color: var(--gray); /* White text for contrast */
    font-size: 16px; /* Adjust text size */
    font-weight: 500; /* Slightly bold for visibility */
    transition: color 0.2s ease; /* Smooth hover effect */
}

.MobileNav a:hover {
    color: #ffe6e6; /* Lighter red on hover */
}



.navItem {
    text-decoration: none;
    color: var(--white);
    font-weight: 500;
    transition: color 0.3s ease, transform 0.2s ease;
}

.navItem:hover {
    color: var(--gray);
    transform: scale(1.1);
}

.active {
    font-weight: bold;
    border-bottom: 3px solid var(--white); /* Adds a distinct underline for the active link */
}

.mobileActive {
    color: var(--red) !important;
}

.button {
    background-color: var(--white);
    color: var(--black);
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.authButtons{
    display: flex;
    gap: 10px;
}

.button:hover {
    background-color: var(--gray);
    transform: scale(1.01); /* Small pop effect */
}

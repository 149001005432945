/* Container for the leaderboard */
.container {
    max-width: 1000px;
    margin: 20px auto;
    text-align: center;
}

/* Leaderboard title */
.title {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--red);
    margin-bottom: 20px;
}

/* Table styling for leaderboard rows */
.table {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Individual row with card-like styling */
.row {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: var(--white);
    border-left: 6px solid var(--red); /* Accent border on the left */
    border-radius: 10px;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.1); /* Enhanced shadow for a bold card effect */
    transition: transform 0.2s, box-shadow 0.2s;
    gap: 20px;
    cursor: pointer;
}

.row:hover {
    transform: translateY(-3px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15); /* Lift effect on hover */
}

/* Styling for the current user when highlighted */
.currentUser {
    border-left: 6px solid var(--gold); /* Gold border for the current user */
}

/* Rank styling with color distinction */
.rank {
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
    min-width: 40px;
    color: var(--black);
}

/* Rank colors for the top 3 */
.rankGold {
    color: var(--gold);
}

.rankSilver {
    color: var(--silver);
}

.rankBronze {
    color: var(--bronze);
}

.rankDefault {
    color: var(--black);
}

/* Main content styling */
.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    flex: 1;
}

.name {
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--black);
}

/* Points and card ID styling horizontally aligned */
.horizontalInfo {
    display: flex;
    gap: 20px;
    font-size: 1.1rem;
    color: var(--black);
}

.points {
    font-weight: bold;
    color: var(--red);
}

/* Ellipsis row styling */
.ellipsis {
    font-size: 1.5rem;
    color: var(--black);
    text-align: center;
    margin: 15px 0;
    opacity: 0.6;
}

/* Main header styling */
.eventsHeader {
    font-size: 2rem;
    font-weight: bold;
    color: var(--red);
    text-align: center;
    margin-bottom: 20px;
}

/* Container for event cards */
.eventsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

/* Event card styling */
.eventCard {
    background-color: var(--white);
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-left: 4px solid var(--red);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Header for event title and status */
.eventHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Event title */
.eventTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--black);
}

/* Status indicator */
.eventStatus {
    font-size: 0.9rem;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    color: var(--white);
    text-transform: uppercase;
}

/* Status active/inactive colors */
.statusActive {
    background-color: var(--red);
}
.statusInactive {
    background-color: var(--gray);
    color: var(--black);
}

/* Description styling */
.eventDescription {
    font-size: 1rem;
    color: var(--black);
    line-height: 1.5;
}

/* Footer styling for points and time */
.eventFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    color: var(--black);
}

/* Points styling */
.eventPoints {
    font-weight: bold;
    color: var(--gold);
}

/* Time styling */
.eventTime {
    font-style: italic;
    color: #999;
}

.profileContainer {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.profileCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

.profileImage {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.profileDetails {
    margin-bottom: 20px;
}

.username {
    font-size: 2rem;
    color: #333;
}

.email {
    font-size: 1rem;
    color: #555;
}

.bio {
    font-size: 1rem;
    color: #777;
    font-style: italic;
    margin-top: 10px;
}

.editButton {
    background-color: #ff1e00;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
}

.editButton:hover {
    background-color: #e01700;
}

.statsSection {
    text-align: left;
}

.statsSection h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
}

.statsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.statCard {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.statCard h3 {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 10px;
}

.statCard p {
    font-size: 1.5rem;
    color: #333;
    font-weight: bold;
}

/* LoginPage.module.css */

.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
}

.title {
    color: #333;
    margin-bottom: 20px;
}

.loginForm {
    width: 300px;
    padding: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.formGroup {
    margin-bottom: 15px;
}

.label {
    display: block;
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
}

.input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
}

.input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}

.loginButton {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.loginButton:hover {
    background-color: #0056b3;
}

.errorMessage {
    color: #e74c3c;
    font-size: 14px;
    margin-bottom: 15px;
    text-align: center;
}

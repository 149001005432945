/* Container for the card list */
.cardList {
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
}

/* Individual card item styling */
.cardItem {
    background-color: var(--white);
    border: 1px solid var(--gray);
    border-left: 5px solid var(--red); /* Accent border on the left */
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: box-shadow 0.3s, transform 0.3s;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.cardItem:hover {
    transform: translateY(-3px); /* Slight lift on hover */
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

/* Text styling inside each card */
.cardItem p {
    margin: 8px 0;
    font-size: 1rem;
    color: var(--black);
}

.emptyState {
    text-align: center;
    margin-top: 20px;
}

